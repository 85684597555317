import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

export default function BlogPost({ data }) {
  console.log(`blog data`, data);
  return (
    <Layout selected="insights">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${data.contentfulBlogPost.title} | Itadise Insights`}</title>
      </Helmet><div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg className="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg className="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">{data.contentfulBlogPost.category || "Article"}</h2>
              <h1 className="mt-2 text-4xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl">{data.contentfulBlogPost.title}</h1>
              <p className="mt-8 text-lg text-gray-500">{data.contentfulBlogPost.previewText.previewText}</p>
              {data.contentfulBlogPost.author && (
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <span className="sr-only">{data.contentfulBlogPost.author.name}</span>
                    {data.contentfulBlogPost.author.picture && <img className="h-10 w-10 rounded-full" src={data.contentfulBlogPost.author.picture.file.url} alt={data.contentfulBlogPost.author.picture.title} />}
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      {data.contentfulBlogPost.author.name}
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime="2020-03-16">
                        {data.contentfulBlogPost.createdAt}
                      </time>
                    </div>
                  </div>
                </div>
              )}
          </div>
          <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.contentfulBlogPost.mainText.childMarkdownRemark.html,
                }}
              />
            </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: {eq: $slug}) {
      slug
      title
      category
      previewText {
        previewText
      }
      mainText {
        childMarkdownRemark {
          html
        }
      }
      createdAt(formatString: "DD MMMM, YYYY")
      coverImage {
        file {
          fileName
          url
        }
      }
      author {
        name
        picture {
          file {
            url
          }
        }
      }
    }
  }
`;